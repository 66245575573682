import React, { useRef, useEffect } from "react";

function AutoPlayVideo({ videoSrc }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play(); // Start playing immediately
    }
  }, [videoRef]);

  return (
    <video
      ref={videoRef}
      src={videoSrc}
      loop // Loop playback
      muted // Remove sound
      playsInline // Improve performance, especially for mobile
      style={{ width: "100%" }} // Adjust to your desired display size
    />
  );
}

export default AutoPlayVideo;
