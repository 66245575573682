import React from "react";
import CTA from "../cta/CTA";
import { BsInstagram } from "react-icons/bs";
import { CiFacebook } from "react-icons/ci";
import "./header.css";
import Logo from "../../assets/logo512.png";
import AutoPlayVideo from "../auto play/AutoPlay";
import Video from "../../assets/video1.mp4";

const Header = () => {
  return (
    <>
      <header>
        <section id="home">
          <div className="container header__container">
            <div className="header-text">
              <img className="logo" src={Logo} alt="" />
              <h1 className="header_text">מאהר נ. תיים הנדסה</h1>
              <h2 className="span1">תכנון, ביצוע, מצוינות</h2>
              <h2 className="header_text">0547555031</h2>
              <div className="header__socials">
                <a
                  href="https://www.facebook.com/m.n.tayyem/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CiFacebook />
                </a>
                <a
                  href="https://www.instagram.com/maher.tayyem/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram />
                </a>
              </div>
              <CTA />
            </div>

            <div className="header-video">
              <AutoPlayVideo videoSrc={Video} />
            </div>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
