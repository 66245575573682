import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./wp.css";

const WP = () => {
  return (
      <div className="fixed-container">
        <a
          href="https://wa.me/+972547555031?text=שלום,%20אני%20מעוניין%20לברר%20לגבי%20השירותים%20שלך."
          target="_blank"
          rel="noreferrer"
          className="float"
        >
          <FaWhatsapp className="contact__option-icon" />
        </a>
      </div>
  );
};

export default WP;
