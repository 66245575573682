import React from "react";
import portfolio1 from "../../assets/portfolio1.jpg";
import portfolio2 from "../../assets/portfolio2.jpg";
import portfolio3 from "../../assets/portfolio3.jpg";
import portfolio4 from "../../assets/portfolio4.jpg";
import portfolio5 from "../../assets/portfolio5.jpg";
import portfolio6 from "../../assets/portfolio6.jpg";
import portfolio7 from "../../assets/portfolio7.jpg";
import portfolio8 from "../../assets/portfolio8.mp4";
import portfolio9 from "../../assets/portfolio9.mp4";

import AutoPlayVideo from "../auto play/AutoPlay";

import "./portfolio.css";

const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>חזון שהפך למציאות</h5>
      <h2>תיק עבודות</h2>

      <div className="container portfolio__container">
        {/* <article className="portfolio__item">
          <div className="portfolio__item-image">
            <AutoPlayVideo videoSrc={portfolio9} />
          </div>
          <h3>עיצוב פנים</h3>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <AutoPlayVideo videoSrc={portfolio9} />
          </div>
          <h3>עיצוב פנים</h3>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <AutoPlayVideo videoSrc={portfolio9} />
          </div>
          <h3>עיצוב פנים</h3>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <AutoPlayVideo videoSrc={portfolio9} />
          </div>
          <h3>עיצוב פנים</h3>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <AutoPlayVideo videoSrc={portfolio9} />
          </div>
          <h3>עיצוב פנים</h3>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <AutoPlayVideo videoSrc={portfolio9} />
          </div>
          <h3>עיצוב פנים</h3>
        </article> */}
        <div className="item_wrap">
          <img src={portfolio1} alt="" className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <AutoPlayVideo videoSrc={portfolio9} className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <img src={portfolio2} alt="" className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <img src={portfolio3} alt="" className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <img src={portfolio4} alt="" className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <AutoPlayVideo videoSrc={portfolio8} className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <img src={portfolio5} alt="" className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <img src={portfolio6} alt="" className="portfolio__item" />
        </div>

        <div className="item_wrap">
          <img src={portfolio7} alt="" className="portfolio__item" />
        </div>
      </div>
    </section>
  );
};

export default portfolio;
