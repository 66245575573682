import emailjs from "emailjs-com";
import React, { useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import "./contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_iins11h",
      "template_k6ebigw",
      form.current,
      "5OHxlHc_u1BbnbeLW"
    );
    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>מוזמנים לפנות אלינו</h5>
      <h2>יצירת קשר</h2>

      <div className="container contact__container">
        <div className="times_phone">
          <span className="phone">טלפון: 077-5080412</span>
          <span>
            ימים ב’ - ה’ 15:00-08:30 , 17:00-19:00 <br /> ימים ו’ - ש’ 14:00-09:00
          </span>
        </div>
        <div className="contact__options">
          <article
            className="contact__option"
            onClick={() => window.open("mailto:mahir5@bezeqint.net", "_blank")}
          >
            <MdOutlineEmail className="contact__option-icon" />
            <h4>אימייל</h4>
            <h5>mahir5@bezeqint.net</h5>
            <a
              href="mailto:mahir5@bezeqint.net"
              target="_blank"
              rel="noreferrer"
            >
              כתבו לנו למייל
            </a>
          </article>

          <article
            className="contact__option"
            onClick={() =>
              window.open("https://maps.app.goo.gl/WxxZMhpey2yJ2e5L6", "_blank")
            }
          >
            <FiMapPin className="contact__option-icon" />
            <h4>מיקום</h4>
            <h5>אעבלין</h5>
            <a
              href="https://maps.app.goo.gl/WxxZMhpey2yJ2e5L6"
              target="_blank"
              rel="noreferrer"
            >
              הוראות הגעה
            </a>
          </article>

          <article
            className="contact__option"
            onClick={() =>
              window.open(
                "https://wa.me/+972547555031?text=שלום,%20אני%20מעוניין%20לברר%20לגבי%20השירותים%20שלך.",
                "_blank"
              )
            }
          >
            <FaWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>972547555031+</h5>
            <a
              href="https://wa.me/+972547555031?text=שלום,%20אני%20מעוניין%20לברר%20לגבי%20השירותים%20שלך."
              target="_blank"
              rel="noreferrer"
            >
              התכתבו איתנו ב WhatsApp
            </a>
          </article>
        </div>

        {/* <div className="times_phone">
          <span>ימים ב’ - ה’ 15:00-08:30 <br /> ימים ו’ - ש’ 13:00-09:00</span>
          <span className="phone">טלפון: 077-5080412</span>
        </div> */}

        {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="שם מלא" required />
          <input type="email" name="email" placeholder="אימייל" required />
          <textarea
            name="message"
            rows="7"
            placeholder="הודעה"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            שלח
          </button>
        </form> */}
      </div>
    </section>
  );
};

export default Contact;
