import React from "react";
import { FaAward, FaUsers } from "react-icons/fa";
import { RiFoldersFill } from "react-icons/ri";
import ME from "../../assets/maherabout.jpg";
import CTA from "../cta/CTA";
import "./about.css";

const About = () => {
  return (
    <section id="about">
      <h5>תכירו</h5>
      <h2>מי אנחנו</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={ME} alt="About Maher Img" />
          </div>
        </div>

        <div className="about__content">
          {/* <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>נסיון</h5>
              <small>מ1991</small>
            </article>

            <article className="about__card">
              <FaUsers className="about__icon" />
              <h5>לקוחות</h5>
              <small>1000+</small>
            </article>

            <article className="about__card">
              <RiFoldersFill className="about__icon" />
              <h5>פרוייקטים</h5>
              <small>1000+</small>
            </article>
          </div> */}

          <h2>
          מאז שנת 1991, משרד מאהר נ. תיים הנדסה צבר מוניטין של מצוינות בתחומי
            ההנדסה, האדריכלות ועיצוב פנים. צוות המומחים שלנו, הכולל מהנדסים
            ואדריכלים מנוסים ומוכשרים, מספק שירותי תכנון קונסטרוקציה מוקפדים,
            עיצוב אדריכלי ייחודי, עיצוב פנים מקצועי ותוכניות הנדסיות מדויקות
            למגוון רחב של פרויקטים – מבנייני מגורים ומסחר ועד לתעשייה ותשתיות.
            גישה חדשנית, שירות אישי ומחויבות לאיכות הם הערכים המנחים אותנו
            בתכנון מבנים יציבים ובטוחים. אנו עובדים בשיתוף פעולה הדוק עם
            לקוחותינו כדי להבין את צרכיהם הייחודיים ולפתח פתרונות מותאמים אישית
            העומדים בסטנדרטים הגבוהים ביותר. עם ניסיון של למעלה מ-30 שנה, אנו
            גאים להעניק ללקוחותינו שירותים איכותיים, עמידה בלוחות זמנים. אם אתם
            מחפשים תכנון קונסטרוקציה, אדריכל מומלץ או עיצוב פנים מעורר השראה –
            צרו קשר עוד היום! בואו נתכנן ולבנות את החלום שלכם, ביחד!
          </h2>

          <CTA />
        </div>
      </div>
    </section>
  );
};

export default About;
