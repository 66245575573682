import React from "react";
import { BsInstagram } from "react-icons/bs";
import { CiFacebook } from "react-icons/ci";
import { FiTwitter } from "react-icons/fi";
import "./footer.css";
import Logo from "../../assets/LOGO TRANSPARENCY black.png";

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer_logo">
        <img className="logo" src={Logo} alt="" />
      </a>

      <ul className="permalinks">
        {/* <li>
          <a href="#">בית</a>
        </li> */}
        <li>
          <a href="#about">אודות</a>
        </li>
        <li>
          <a href="#services">שירותים</a>
        </li>
        <li>
          <a href="#portfolio">תיק עבודות</a>
        </li>
        <li>
          <a href="#contact">יצירת קשר</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a
          href="https://www.facebook.com/m.n.tayyem/"
          target="_blank"
          rel="noreferrer"
        >
          <CiFacebook />
        </a>
        <a
          href="https://www.instagram.com/maher.tayyem/"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Maher Tayyem. All rights reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
