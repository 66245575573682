import React from "react";
import { BsCheck2All } from "react-icons/bs";
import "./services.css";

const Services = () => {
  return (
    <section id="services">
      <h5>מה אנחנו מציעים</h5>
      <h2>השירותים שלנו</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>תכנון וניהול פרויקטים מלא</h3>
          </div>
          <ul className="service__list">
            <li>
              <BsCheck2All className="service__list-icon" />
              <p>תכנון קונספטואלי</p>
            </li>

            <li>
              <BsCheck2All className="service__list-icon" />
              <p>שרטוטים ארכיטקטוניים והנדסיים מפורטים</p>
            </li>

            <li>
              <BsCheck2All className="service__list-icon" />
              <p>תקשורת ותיאום יעילים בין גורמי ההנדסה והקבלנים</p>
            </li>

            <li>
              <BsCheck2All className="service__list-icon" />
              <p>דגש על עמידה בלוחות הזמנים ובתקציב של הלקוח</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>תכנון והנדסה</h3>
          </div>
          <ul className="service__list">
            <li>
              <BsCheck2All className="service__list-icon" />
              <p>תכניות קונסטרוקציה מלאות</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>חישוב כמויות</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>אומדן עלויות (בכפוף להסכם מראש)</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>בדיקת חשבונות  (בכפוף להסכם מראש)</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>פיקוח על</p>
            </li>
          </ul>
        </article>


        <article className="service">
          <div className="service__head">
            <h3>עיצוב אדריכלי מקצועי</h3>
          </div>
          <ul className="service__list">
            <li>
              <BsCheck2All className="service__list-icon" />
              <p>פיתוח קונספט עיצובי</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>תכנון אדריכלי מפורט</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>הדמיות תלת-ממדיות</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>בחירת חומרים וגמרים (בכפוף להסכם מראש)</p>
            </li>


            <li>
              <BsCheck2All className="service__list-icon" />
              <p>עיצוב פנים</p>
            </li>
          </ul>
        </article>


      </div>
    </section>
  );
};

export default Services;
