import React, { useState, useEffect } from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import Portfolio from "./components/portfolio/Portfolio";
import Services from "./components/services/Services";
import WP from "./components/wp/WP";
import Read from "./components/read/Read";
import { Helmet } from "react-helmet";

const App = () => {
  const [activeNav, setActiveNav] = useState("#");
  const metaTitle =
    "מהנדס קונסטרוקציה, אדריכל ועיצוב פנים בחיפה והצפון | מאהר נ. תיים הנדסה";

  const handleScroll = () => {
    const sections = [
      "home",
      "about",
      "services",
      "read",
      "portfolio",
      "contact",
    ];

    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const rect = section.getBoundingClientRect();
        const midY = (rect.top + rect.bottom) / 2; // Calculate midpoint
        if (midY >= 0 && midY <= window.innerHeight) {
          setActiveNav(section.id === "home" ? "#" : `#${section.id}`);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <Nav activeNav={activeNav} setActiveNav={setActiveNav} />
      <div className="wp_container">
        <WP />
        <Header />
        <About id="about" />
        {/* <Experience /> */}
        <Services id="services" />
        {/* <Read id="read" /> */}
        <Portfolio id="portfolio" />
      </div>
      {/* <Testimonials id="testimonials"/> */}
      <Contact id="contact" />
      <Footer />
    </>
  );
};

export default App;
